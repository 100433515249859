import React from "react"

import "./november-offer-tnc.scss"

const NovemberOfferTnc = () => {
  return (
    <>
    <div className="main-content">
       <div className="title" style={{ textAlign: "center" }}>
       GREAT NOVEMBER SALE OFFER TERMS & CONDITIONS
       </div>
       <p className="sub-heading">DEFINITIONS</p>
       <p>
         For the purpose of these terms and conditions (“
         <b>Terms and Conditions</b>”):
       </p>
       <ul style={{ listStyle: "disc" }}>
         <li>
           <p>
             <b>“User”</b> shall mean a business entity that has On-Boarded on
             the SOLV Platform using its legal name and mobile number, and is
             legally subsisting during the Offer Period;
           </p>
         </li>
         <li>
           <p>
             <b>“Eligible User”</b> shall mean a User which satisfies the
             conditions of this Offer and becomes eligible to receive the
             discounts as set out in this Offer;
           </p>
         </li>
         <li>
           <p>
             <b>“FMCG and HoReCa Products”</b> means fast moving consumer
             goods, including both food and non-food products, processed food or hotel,
             restaurant and catering related products;
           </p>
         </li>
         <li>
           <p>
             <b>
             “Consumer Electronic Products”
             </b> means mobile accessories and feature phones;
           </p>
         </li>
         <li>
           <p>
             <b>“Offer”</b> shall mean the various discount schemes offered to
             the Eligible Users for buying specified (i) FMCG and HoReCa Products; (ii) Consumer Electronic products; (iii) Apparel products; (iv) Home furnishing products; and (v) Footwear products, as detailed below during the Offer Period in the eligible pin codes;
           </p>
         </li>
         <li>
           <p>
             <b>“Offer Period”</b> shall mean the period as set out in the
             table below, during which the Eligible Users shall be entitled to
             avail the respective Offer.
           </p>
         </li>
         <li>
           <p>
             <b>“On-Board” or “On-Boarding”</b> means the downloading and
             installation of the SOLV application by the User, providing
             relevant Merchant Information to SOLV, successful verification of
             Merchant Information and registration of User as a seller/buyer on
             the SOLV Platform by obtaining a unique SOLV identification
             number;
           </p>
         </li>
         <li>
           <p>
             <b>“Primary Terms and Conditions”</b> shall mean the terms and
             conditions governing the usage of the SOLV platform, as applicable
             to the Users as separately provided on the SOLV portal and/or
             application in addition to these Terms and Conditions set out
             herein.
           </p>
         </li>
       </ul>
       <p>
         Any capitalised terms used in these Terms and Conditions, however not
         defined herein, shall have the same meanings as attributed to such
         terms in the Primary Terms and Conditions.
       </p>
       <p className="sub-heading">OFFERS AND CONDITIONS:</p>
       <p className="sub-heading">A. FMCG or HoReCa Products</p>
       <div className="table-responsive des-tos">
         <table
           className="table table-bordered"
           style={{ width: "100%", margin: "0px auto" }}
         >
           <tbody>
             <tr>
               <th style={{ width: "10%" }}>Sr. No.</th>
               <th>Offer Period</th>
               {/* <th>Campaign</th> */}
               <th>Product Type for offer</th>
               <th>Buyer Location</th>
               <th>Minimum order value (Rs.)</th>
               <th>Discount Value</th>
               <th>No. of coupons</th>
               <th>Coupon Codes</th>
               <th>Details</th>
             </tr>

  
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="5"
                 scope="rowgroup"
               >
                 1
               </td>
               <td rowSpan="5" scope="rowgroup">
               1 November 2022 to 30 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="5" scope="rowgroup">
               All FMCG and HoReCa Products excluding oil, sugar and atta
               </td>
               <td rowSpan="5" scope="rowgroup">
               All
               </td>
               <td style={{ textAlign: "right" }}>3,000</td>
               <td style={{ textAlign: "right" }}>30</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSG30</td>
               <td>
                 <b>Rs.30 off on orders worth Rs.3,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>65</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSG65</td>
               <td>
                 <b>Rs.65 off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>10,000</td>
               <td style={{ textAlign: "right" }}>140</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSG140</td>
               <td>
                 <b>Rs.140 off on orders worth Rs.10,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>15,000</td>
               <td style={{ textAlign: "right" }}>240</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSG240</td>
               <td>
                 <b>Rs.240 off on orders worth Rs.15,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>20,000</td>
               <td style={{ textAlign: "right" }}>400</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSG400</td>
               <td>
                 <b>Rs.400 off on orders worth Rs.20,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="5"
                 scope="rowgroup"
               >
                 2
               </td>
               <td rowSpan="5" scope="rowgroup">
               1 November 2022 to 30 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="5" scope="rowgroup">
               All oil, sugar and atta products included in FMCG and HoReCa Products 
               </td>
               <td rowSpan="5" scope="rowgroup">
               Bangalore
               </td>
               <td style={{ textAlign: "right" }}>3,000</td>
               <td style={{ textAlign: "right" }}>18</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSB18</td>
               <td>
                 <b>Rs.18 off on orders worth Rs.3,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>35</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSB35</td>
               <td>
                 <b>Rs.35 off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>12,000</td>
               <td style={{ textAlign: "right" }}>96</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSB96</td>
               <td>
                 <b>Rs.96 off on orders worth Rs.12,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>21,000</td>
               <td style={{ textAlign: "right" }}>189</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSB189</td>
               <td>
                 <b>Rs.189 off on orders worth Rs.21,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>40,000</td>
               <td style={{ textAlign: "right" }}>400</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSB400</td>
               <td>
                 <b>Rs.400 off on orders worth Rs.40,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="5"
                 scope="rowgroup"
               >
                 3
               </td>
               <td rowSpan="5" scope="rowgroup">
               1 November 2022 to 30 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="5" scope="rowgroup">
               All oil, sugar and atta products included in FMCG and HoReCa Products
               </td>
               <td rowSpan="5" scope="rowgroup">
               Chennai
               </td>
               <td style={{ textAlign: "right" }}>3,000</td>
               <td style={{ textAlign: "right" }}>24</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSC24</td>
               <td>
                 <b>Rs.24 off on orders worth Rs.3,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>45</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSC45</td>
               <td>
                 <b>Rs.45 off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>12,000</td>
               <td style={{ textAlign: "right" }}>120</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSC120</td>
               <td>
                 <b>Rs.120 off on orders worth Rs.12,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>21,000</td>
               <td style={{ textAlign: "right" }}>231</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSC231</td>
               <td>
                 <b>Rs.231 off on orders worth Rs.21,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>35,000</td>
               <td style={{ textAlign: "right" }}>420</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSC420</td>
               <td>
                 <b>Rs.420 off on orders worth Rs.35,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
           
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="4"
                 scope="rowgroup"
               >
                 4
               </td>
               <td rowSpan="4" scope="rowgroup">
               1 November 2022 to 30 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="4" scope="rowgroup">
               All oil, sugar and atta products included in FMCG and HoReCa Products
               </td>
               <td rowSpan="4" scope="rowgroup">
               Delhi-NCR
               </td>
               <td style={{ textAlign: "right" }}>3,000</td>
               <td style={{ textAlign: "right" }}>24</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSD24</td>
               <td>
                 <b>Rs.24 off on orders worth Rs.3,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>45</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSD45</td>
               <td>
                 <b>Rs.45 off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>12,000</td>
               <td style={{ textAlign: "right" }}>120</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSD120</td>
               <td>
                 <b>Rs.120 off on orders worth Rs.12,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>30,000</td>
               <td style={{ textAlign: "right" }}>360</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSD360</td>
               <td>
                 <b>Rs.360 off on orders worth Rs.30,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             

             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="5"
                 scope="rowgroup"
               >
                 5
               </td>
               <td rowSpan="5" scope="rowgroup">
               1 November  2022 to 30 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="5" scope="rowgroup">
               All oil, sugar and atta products included in FMCG and HoReCa Products
               </td>
               <td rowSpan="5" scope="rowgroup">
               Hyderabad
               </td>
               <td style={{ textAlign: "right" }}>3,000</td>
               <td style={{ textAlign: "right" }}>18</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSH18</td>
               <td>
                 <b>Rs.18 off on orders worth Rs.3,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>35</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSH35</td>
               <td>
                 <b>Rs.35 off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>12,000</td>
               <td style={{ textAlign: "right" }}>96</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSH96</td>
               <td>
                 <b>Rs.96 off on orders worth Rs.12,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>21,000</td>
               <td style={{ textAlign: "right" }}>189</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSH189</td>
               <td>
                 <b>Rs.189 off on orders worth Rs.21,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>40,000</td>
               <td style={{ textAlign: "right" }}>400</td>
               <td style={{ textAlign: "center" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>NSH400</td>
               <td>
                 <b>Rs.400 off on orders worth Rs.40,000 or more </b>
                 <br></br>
                 Applicable for unlimited times till offer end date.
               </td>
             </tr>

              
           </tbody>
         </table>
       </div>   
       <p className="sub-heading">B. Consumer Electronic Products</p>
       <div className="table-responsive des-tos">
         <table
           className="table table-bordered"
           style={{ width: "100%", margin: "0px auto" }}
         >
           <tbody>
             <tr>
               <th style={{ width: "10%" }}>Sr. No.</th>
               <th>Offer Period</th>
               {/* <th>Campaign</th> */}
               <th>Product Type for offer</th>
               <th>Buyer Location</th>
               <th>Minimum order value (Rs.)</th>
               <th>Discount Value</th>
               <th>No. of coupons</th>
               <th>Coupon Codes</th>
               <th>Details</th>
             </tr>

  
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="3"
                 scope="rowgroup"
               >
                 1
               </td>
               <td rowSpan="3" scope="rowgroup">
               1 November 2022 to 28 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="3" scope="rowgroup">
               All mobile accessories
               </td>
               <td rowSpan="3" scope="rowgroup">
               All Cities
               </td>
               <td style={{ textAlign: "right" }}>1,499</td>
               <td style={{ textAlign: "right" }}>25</td>
               <td style={{ textAlign: "center" }}>2</td>
               <td style={{ textAlign: "center" }}>NSMA25</td>
               <td>
                 <b>Rs.25 off on orders worth Rs.1,499 or more </b>
                 <br></br>
                 Applicable twice times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>1,999</td>
               <td style={{ textAlign: "right" }}>50</td>
               <td style={{ textAlign: "center" }}>2</td>
               <td style={{ textAlign: "center" }}>NSMA50</td>
               <td>
                 <b>Rs.50 off on orders worth Rs.1,999 or more </b>
                 <br></br>
                 Applicable twice till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>2,999</td>
               <td style={{ textAlign: "right" }}>125</td>
               <td style={{ textAlign: "center" }}>2</td>
               <td style={{ textAlign: "center" }}>NSMA125</td>
               <td>
                 <b>Rs.125 off on orders worth Rs.2,999 or more </b>
                 <br></br>
                 Applicable twice till offer end date.
               </td>
             </tr>
       
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="3"
                 scope="rowgroup"
               >
                 2
               </td>
               <td rowSpan="3" scope="rowgroup">
               1 November 2022 to 28 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="3" scope="rowgroup">
               All feature phones
               </td>
               <td rowSpan="3" scope="rowgroup">
               All
               </td>
               <td style={{ textAlign: "right" }}>1,999</td>
               <td style={{ textAlign: "right" }}>25</td>
               <td style={{ textAlign: "center" }}>2</td>
               <td style={{ textAlign: "center" }}>NSFP25</td>
               <td>
                 <b>Rs.25 off on orders worth Rs.1,999 or more </b>
                 <br></br>
                 Applicable twice times till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>2,999</td>
               <td style={{ textAlign: "right" }}>50</td>
               <td style={{ textAlign: "center" }}>2</td>
               <td style={{ textAlign: "center" }}>NSFP50</td>
               <td>
                 <b>Rs.50 off on orders worth Rs.2,999 or more </b>
                 <br></br>
                 Applicable twice till offer end date.
               </td>
             </tr>
             <tr>
               <td style={{ textAlign: "right" }}>4,999</td>
               <td style={{ textAlign: "right" }}>100</td>
               <td style={{ textAlign: "center" }}>2</td>
               <td style={{ textAlign: "center" }}>NSFP100</td>
               <td>
                 <b>Rs.100 off on orders worth Rs.4,999 or more </b>
                 <br></br>
                 Applicable twice till offer end date.
               </td>
             </tr>
                      
           </tbody>
         </table>
       </div>   

       <p className="sub-heading">C. Apparel Products</p>
       <div className="table-responsive des-tos">
         <table
           className="table table-bordered"
           style={{ width: "100%", margin: "0px auto" }}
         >
           <tbody>
             <tr>
               <th style={{ width: "10%" }}>Sr. No.</th>
               <th>Offer Period</th>
               {/* <th>Campaign</th> */}
               <th>Product Type for offer</th>
               <th>Buyer Location</th>
               <th>Minimum order value (Rs.)</th>
               <th>Maximum order value (Rs.)</th>
               <th>Discount Percentage(%)</th>
               <th>No. of coupons</th>
               <th>Coupon Codes</th>
               <th>Details</th>
             </tr>

  
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="4"
                 scope="rowgroup"
               >
                 1
               </td>
               <td rowSpan="4" scope="rowgroup">
               5 November 2022 to 30 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="4" scope="rowgroup">
               All Apparel products 
               </td>
               <td rowSpan="4" scope="rowgroup">
               All Cities
               </td>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>15,000</td>
               <td style={{ textAlign: "center" }}>3%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSA3</td>
               <td>
                 <b>Flat 3% off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>15,000</td>
               <td style={{ textAlign: "right" }}>30,000</td>
               <td style={{ textAlign: "center" }}>4%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSA4</td>
               <td>
                 <b>Flat 4% off on orders worth Rs.15,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>30,000</td>
               <td style={{ textAlign: "right" }}>49,000</td>
               <td style={{ textAlign: "center" }}>5%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSA5</td>
               <td>
                 <b>Flat 5% off on orders worth Rs.30,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>49,000</td>
               <td style={{ textAlign: "right" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>6%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSA6</td>
               <td>
                 <b>Flat 6% off on orders worth Rs.49,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>                  
           </tbody>
         </table>
       </div> 

       <p className="sub-heading">D. Home Furnishing Products</p>
       <div className="table-responsive des-tos">
         <table
           className="table table-bordered"
           style={{ width: "100%", margin: "0px auto" }}
         >
           <tbody>
             <tr>
               <th style={{ width: "10%" }}>Sr. No.</th>
               <th>Offer Period</th>
               {/* <th>Campaign</th> */}
               <th>Product Type for offer</th>
               <th>Buyer Location</th>
               <th>Minimum order value (Rs.)</th>
               <th>Maximum order value (Rs.)</th>
               <th>Discount Percentage(%)</th>
               <th>No. of coupons</th>
               <th>Coupon Codes</th>
               <th>Details</th>
             </tr>

  
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="4"
                 scope="rowgroup"
               >
                 1
               </td>
               <td rowSpan="4" scope="rowgroup">
               5 November 2022 to 30 November 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="4" scope="rowgroup">
               All Home Furnishing products 
               </td>
               <td rowSpan="4" scope="rowgroup">
               All Cities
               </td>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>15,000</td>
               <td style={{ textAlign: "center" }}>3%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSHF3</td>
               <td>
                 <b>Flat 3% off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>15,000</td>
               <td style={{ textAlign: "right" }}>30,000</td>
               <td style={{ textAlign: "center" }}>4%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSHF4</td>
               <td>
                 <b>Flat 4% off on orders worth Rs.15,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>30,000</td>
               <td style={{ textAlign: "right" }}>49,000</td>
               <td style={{ textAlign: "center" }}>5%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSHF5</td>
               <td>
                 <b>Flat 5% off on orders worth Rs.30,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>49,000</td>
               <td style={{ textAlign: "right" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>6%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSHF6</td>
               <td>
                 <b>Flat 6% off on orders worth Rs.49,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>                  
           </tbody>
         </table>
       </div> 
       <p className="sub-heading">E. Footwear Products</p>
       <div className="table-responsive des-tos">
         <table
           className="table table-bordered"
           style={{ width: "100%", margin: "0px auto" }}
         >
           <tbody>
             <tr>
               <th style={{ width: "10%" }}>Sr. No.</th>
               <th>Offer Period</th>
               {/* <th>Campaign</th> */}
               <th>Product Type for offer</th>
               <th>Buyer Location</th>
               <th>Minimum order value (Rs.)</th>
               <th>Maximum order value (Rs.)</th>
               <th>Discount Percentage(%)</th>
               <th>No. of coupons</th>
               <th>Coupon Codes</th>
               <th>Details</th>
             </tr>

  
             <tr>
               <td
                 style={{ textAlign: "center" }}
                 rowSpan="4"
                 scope="rowgroup"
               >
                 1
               </td>
               <td rowSpan="4" scope="rowgroup">
               5 November 2022 to 30 2022
               </td>
               {/* <td rowSpan="4" scope="rowgroup">
               Monsoon Sale
               </td> */}
               <td rowSpan="4" scope="rowgroup">
               All footwear products 
               </td>
               <td rowSpan="4" scope="rowgroup">
               All Cities
               </td>
               <td style={{ textAlign: "right" }}>5,000</td>
               <td style={{ textAlign: "right" }}>10,000</td>
               <td style={{ textAlign: "center" }}>4%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSFW4</td>
               <td>
                 <b>Flat 4% off on orders worth Rs.5,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>10,000</td>
               <td style={{ textAlign: "right" }}>30,000</td>
               <td style={{ textAlign: "center" }}>5%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSFW5</td>
               <td>
                 <b>Flat 5% off on orders worth Rs.10,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>30,000</td>
               <td style={{ textAlign: "right" }}>49,000</td>
               <td style={{ textAlign: "center" }}>6%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSFW6</td>
               <td>
                 <b>Flat 6% off on orders worth Rs.30,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>
             <tr>
             <td style={{ textAlign: "right" }}>49,000</td>
               <td style={{ textAlign: "right" }}>Unlimited</td>
               <td style={{ textAlign: "center" }}>7%</td>
               <td style={{ textAlign: "center" }}>3</td>
               <td style={{ textAlign: "center" }}>NSFW7</td>
               <td>
                 <b>Flat 7% off on orders worth Rs.49,000 or more </b>
                 <br></br>
                 Applicable thrice till offer end date.
               </td>
             </tr>                  
           </tbody>
         </table>
       </div> 
       <p className="sub-heading">OTHER OFFER DETAILS AND CONDITIONS:</p>
       <ul className="liparent" style={{ listStyleType: "decimal" }}>
         <li>
           <p>
             The User will be eligible to use all the codes indicated above
             only{" "}
             <b>
               <u>during the Offer Period.</u>
             </b>{" "}
             In addition to the Offers mentioned above, the User may be
             eligible for other Offers depending on the number and value of
             transactions successfully undertaken on the Platform. The User
             must select and apply such promotional codes during check-out to
             avail the discount.
           </p>
         </li>
         <li>
           <p>
             Upon completing a transaction of specified value, in the event a
             User receives coupon codes for availing discounts on subsequent
             transactions, the invoice value of such subsequent transactions
             should be for an amount which is equal to or greater than the
             invoice value of the qualifying transaction which was undertaken
             first.
           </p>
         </li>
         <li>
           <p>
             In the event of any newly On-Boarded User, the promotional coupon
             codes may not immediately get activated and such activation may
             take up to 24 hours. During such interim period, the new Users are
             encouraged to contact our sales officers/help desk for receiving
             the relevant promotional codes to be applied for any transaction.
           </p>
         </li>
         <li>
           <p>
             SOLV shall only process orders for products in serviceable pin
             codes listed out in <b>Annexure</b> below. However it is
             clarified that the attached list of serviceable pin codes is
             tentative and subject to change from time to time without prior
             notice, depending on notification of containment zones on account
             of Covid-19 related restrictions imposed by governmental
             authorities, operational challenges, etc. The Users must seek the
             assistance of relevant SOLV sales officers to ascertain the up to
             date list of serviceable pin codes.
           </p>
         </li>
         <li>
           <p>
             If the User places a purchase order on the Platform, however the
             relevant seller does not accept such purchase order or the order
             is not processed, the User’s discount voucher shall not be
             considered as consumed. Further, if the User places an order for
             certain quantity of products on the Platform exceeding the
             threshold amount of invoice values mentioned above, however the
             order is only partially fulfilled by the seller as a result of
             which the invoice value falls below the threshold amounts, any
             redemption of discount voucher by the User shall be at the
             discretion and in the manner determined by SOLV.
           </p>
         </li>
         <li>
           <p>
             During the Offer Period, payment for orders by way of
             advance/pre-paid mode shall not be available on the Platform for
             availing the Offers.
           </p>
         </li>
         <li>
           <p>
             The Eligible Users shall not be entitled to place reverse pick-up
             orders during the Offer Period. In case an Eligible User orders
             for products and avails the discount, such Eligible User shall not
             be entitled to return the goods. In the case the Eligible User
             wants reverse pick-up services, the discount availed by the User
             shall be forfeited and such amount will be adjusted in the manner
             determined by SOLV.
           </p>
         </li>
         <li>
           <p>
             Any discount voucher once applied by Eligible Users for placing
             orders on the Platform (including in case of cancelled/RTO orders)
             shall be considered as consumed, unless otherwise stated in these
             Terms and Conditions specifically.
           </p>
         </li>
         <li>
           <p>
             In the event any User is eligible for more than one Offer, such
             User shall be entitled to choose the Offer which the User wants to
             avail, subject to the following conditions:
           </p>
           <ol style={{ listStyleType: "upper-roman" }}>
             <li>
               <p>No two Offers can be clubbed for a single order;</p>
             </li>
             <li>
               <p>
                 The relevant seller must receive the full invoice value from
                 the Eligible User;
               </p>
             </li>
             <li>
               <p>
                 In case of COD payments, the Eligible User shall make payment
                 of the invoice amount net of the applicable discount amount.
               </p>
             </li>
           </ol>
         </li>
         <li>
           <p>
             The thresholds of minimum invoice amounts which are required to be
             fulfilled by Eligible Users for availing the Offers as set out in
             these Terms and Conditions shall be such amounts net off all
             applicable taxes.
           </p>
         </li>
         <li>
           <p>
             The participation in the Offer is entirely voluntary and it is
             understood that the participation by the Users shall be deemed to
             have been made on a voluntary basis.
           </p>
         </li>
         <li>
           <p>
             In all matters relating to the Offer, including the application of
             discount, the decisions of SOLV shall be final and binding.
           </p>
         </li>
         <li>
           <p>
             Any person availing this Offer shall be deemed to have accepted
             these Terms. The Users shall continue to be bound to comply with
             the Primary Terms and Conditions and these Terms shall be in
             addition to and not in substitution /derogation to the Primary
             Terms and Conditions governing the usage of SOLV services.
           </p>
         </li>
         <li>
           <p>
             SOLV reserves the right to modify at any time, all or any of the
             terms applicable to the Offer, including the terms contained
             herein, without assigning any reasons or without any prior
             intimation whatsoever. SOLV also reserves the right to discontinue
             or terminate the Offer without assigning any reasons or without
             any prior intimation whatsoever at any time during the Offer
             Period.
           </p>
         </li>
         <li>
           <p>
             The Eligible Users shall not be entitled to assign the rights and
             benefits arising under this Offer to any other person. No requests
             for transfer or assignment of the benefits under the Offer shall
             be entertained.
           </p>
         </li>
         <li>
           <p>
             By participating in this Offer, the User expressly agrees that
             SOLV or any of its affiliates will not be liable or responsible
             for any loss or damage whatsoever that you may suffer, directly or
             indirectly, in connection with this Offer, including but not
             limited to that associated with his/her use or delivery or misuse
             of any promotional codes and/or benefit provided hereunder. In the
             event SOLV suffers any loss or damage arising out of or resulting
             from any inaccurate information provided by the User or breach of
             any applicable law or terms and conditions, the User shall be
             liable to indemnify SOLV for all the loss and damage incurred.
           </p>
         </li>
         <li>
           <p>
             Under no circumstances will the benefit being offered under this
             Offer be provided in cash by SOLV, i.e. there are no cash or other
             alternatives available in whole or in part, in relation to the
             benefits under this Offer.
           </p>
         </li>
         <li>
           <p>
             SOLV reserves the right to disqualify any merchant establishment
             or User from the benefits of the Offer and initiate appropriate
             legal action, if any fraudulent activity is identified as being
             carried out for the purpose of availing the benefits under the
             Offer. Further, SOLV reserves the right to deny honouring the
             Offer or any benefit thereof on the grounds of suspicion or abuse
             of the Offer by any User without providing the User any
             explanation thereof.
           </p>
         </li>
         <li>
           <p>
             Any queries or disputes from Users regarding issuance/denial of
             discount, can only be raised within 30 days from the date of
             expiry of Offer Period.
           </p>
         </li>
         <li>
           <p>
             All communication and notices about this Offer should be addressed
             to &nbsp;
             <a className="mail-anchor" href="mailto:cs@solvezy.com">
               <strong>cs@solvezy.com</strong>
             </a>
             .
           </p>
         </li>
         <li>
           <p>
             All disputes are subject to the exclusive jurisdiction of the
             competent courts/tribunals of Bangalore.
           </p>
         </li>
       </ul>
       <p className="sub-heading text-center" id="annexture-section">
         ANNEXURE
       </p>
       <p className="sub-heading text-center">SERVICEABLE PIN CODES</p>
       <p>
         1.{" "}
         <a
           className="mail-anchor"
           href={`/FMCG-&-Horeca-serviceable-pincodes-March-Offer.xlsx`}
           download
         >
           Pin codes serviceable for FMCG & HoReCa
         </a>
       </p>
<p>
         2.{" "}
         <a
           className="mail-anchor"
           href={`/Consumer-Electronics-serviceable-Pincodes-March-Offer.xlsb`}
           download
         >
           Pin codes serviceable for Consumer Electronics
         </a>
       </p>
        <p>
         3.{" "}
         <a
           className="mail-anchor"
           href={`/Apparel-serviceable-Pincodes-March-Offer.xlsx`}
           download
         >
           Pin codes serviceable for Apparel and Fashion
         </a>
       </p>
       <p>
         4.{" "}
         <a
           className="mail-anchor"
           href={`/Home-Furnishings-serviceable-Pincodes-March-Offer.xlsx`}
           download
         >
           Pin codes serviceable for Home Furnishings
         </a>
       </p>
       <p>
         5.{" "}
         <a
           className="mail-anchor"
           href={`/Footwear-serviceable-Pincodes-March-Offer.xlsx`}
           download
         >
           Pin codes serviceable for Footwear
         </a>
       </p>

     </div>
 </>
  )
}

export default NovemberOfferTnc
